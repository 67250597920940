.snack_container {
  padding: 1rem;

  strong {
    padding-right: 0.2rem;
  }

  button {
    margin: 0 1rem 0.4rem 0;
  }
}